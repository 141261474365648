import React from "react";
import { useTranslations } from "next-intl";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

type AccessDeniedOverlayProps = {};

export default function AccessDeniedOverlay({
  ...props
}: AccessDeniedOverlayProps): JSX.Element {
  const t = useTranslations();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={64} />
      <Stack
        sx={{
          maxWidth: "800px",
          textAlign: "center",
        }}
      >
        {" "}
        <Typography
          variant={"h6"}
          sx={{ mx: "auto", mt: 3, color: theme.palette.primary.main }}
        >
          {t("access_not_allowed")}
        </Typography>
        <Typography
          variant={"h6"}
          sx={{
            mx: "auto",
            mt: 2,
            color: "rgba(0,0,0,0.56)",
            fontSize: "1rem",
            fontWeight: "500",
          }}
        >
          {t("auto_redirect")}
        </Typography>
      </Stack>
    </Stack>
  );
}
