import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { GetStaticProps } from "next";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useKeycloak } from "@react-keycloak/ssr";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import getConfig from "lib/config";
import Link from "next/link";
import useUserInfo from "../../hooks/useUserInfo";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { HeaderAppBar } from "components/header/HeaderAppBar";
import { Main } from "components/layouts/Main";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useStoreState } from "store/hooks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchUserSettings, saveUserSettings } from "store/actions/users";
import { languageToI18nLocale } from "lib/util";
import useUserRoles from "hooks/useUserRoles";

import { ROLES } from "lib/constants";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import AccessDeniedOverlay from "components/common/helpers/redirect/AccessDeniedOverlay";
import { useAccessControl } from "hooks/useAccessControl";

const { publicRuntimeConfig } = getConfig();

const { REACT_APP_HELPDOCS_URL, REACT_APP_OLD_DASHBOARD_URL } =
  publicRuntimeConfig;

const DashboardPage = () => {
  const router = useRouter();
  const { tenant } = router.query;
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const userInfo = useUserInfo();
  const t = useTranslations();
  const roles = useUserRoles();
  const theme = useTheme();
  const accessRights = useAccessControl(roles, tenant, true);
  const userSettings = useStoreState((state) => state.userSettings);
  useEffect(() => {
    if (!userSettings && dispatch) {
      dispatch(fetchUserSettings());
    }
  }, [userSettings, dispatch]);

  if (
    accessRights.hasManagementStatisticsAccessOnly ||
    accessRights.hasDesignAccessOnly ||
    accessRights.hasSmartDocAccessOnly
  ) {
    return <AccessDeniedOverlay />;
  }

  return (
    <>
      <HeaderAppBar />
      <Main>
        <Stack>
          <Paper
            sx={{
              p: 4,
              borderRadius: "15px",
              mt: 2,
              mx: "auto",
              width: "1000px",
            }}
          >
            <Typography variant="h3" gutterBottom>
              {t("dashboard_welcome")}
            </Typography>
            <Typography gutterBottom sx={{ mb: 2 }}>
              {t("dashboard_intro")}
            </Typography>
            <Stack direction="row" spacing={2}>
              <AccountCircle />
              <Typography>
                {t("dashboard_logged_in_as")}{" "}
                {userInfo?.preferred_username && userInfo.preferred_username}
              </Typography>
            </Stack>
          </Paper>
          <Stack
            direction="row"
            spacing={12}
            sx={{ mt: 5, mx: "auto" }}
            justifyContent="center"
          >
            {roles && roles.includes(ROLES.PAGE.DIALOGUE_EDITOR) && (
              <Paper sx={{ p: 4, borderRadius: "15px", width: "450px" }}>
                <Typography variant="h5" gutterBottom>
                  {t("dashboard_nocode")}
                </Typography>
                <Typography gutterBottom>
                  {t("dashboard_nocode_intro")}
                </Typography>
                <List>
                  <ListItemButton>
                    <a
                      href={REACT_APP_HELPDOCS_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("dashboard_nocode_learn_more")}
                    </a>
                  </ListItemButton>
                  <ListItemButton>
                    <Link href={`${tenant}/dialogue-editor`} passHref>
                      <a>{t("dashboard_nocode_get_started")}</a>
                    </Link>
                  </ListItemButton>
                </List>
              </Paper>
            )}
            {roles && roles.includes(ROLES.PAGE.CONVERSATIONS) && (
              <Paper sx={{ p: 4, borderRadius: "15px", width: "450px" }}>
                <Typography variant="h5" gutterBottom>
                  {t("dashboard_omnichannel")}
                </Typography>
                <Typography gutterBottom>
                  {t("dashboard_omnichannel_intro")}
                </Typography>
              </Paper>
            )}
          </Stack>
        </Stack>
      </Main>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      messages: {
        ...(await import(
          `../../locales/${languageToI18nLocale(locale)}/translation.json`
        )),
        ...(await import(
          `../../locales/${languageToI18nLocale(locale)}/additional.json`
        )),
      },
      // Will be passed to the page component as props
    },
  };
};

export const getStaticPaths = async () => {
  return {
    paths: [], //indicates that no page needs be created at build time
    fallback: "blocking", //indicates the type of fallback
  };
};

export default DashboardPage;
