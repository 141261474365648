import { useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import useUserRoles from "hooks/useUserRoles";
import { ROLES } from "lib/constants";

export const roleCheck = (
  roles: string[] | undefined,
  roleKey: keyof typeof ROLES
): boolean => {
  if (!roles) {
    return false; // Returns false if roles array is undefined
  }
  const role = ROLES[roleKey];
  return roles.some((r) => r === role);
};

export const useAccessControl = (
  roles: string[] | undefined,
  tenant: string | string[] | undefined,
  redirect?: boolean
) => {
  const router = useRouter();
  const rolesWithPage = roles?.filter((entry: string) =>
    entry.startsWith("page_")
  );

  const accessRights = useMemo(() => {
    return {
      hasManagementStatisticsAccessOnly: roles?.includes(
        ROLES.PAGE.MANAGEMENT_STATISTICS
      ),
      hasDesignAccessOnly: roles?.some((r) =>
        [ROLES.PAGE.DIALOGUE_EDITOR_DESIGN].includes(r)
      ),
      hasSmartDocAccessOnly:
        (rolesWithPage?.length || 0) > 0 &&
        rolesWithPage?.every((entry: string | string[]) =>
          entry.includes("smartdoc")
        ),
    };
  }, [roles]);

  // Redirect logic
  useEffect(() => {
    if (!redirect) return;
    if (accessRights.hasManagementStatisticsAccessOnly) {
      router.push(`/${tenant}/management-statistics`).then((r) => r);
    } else if (accessRights.hasDesignAccessOnly) {
      router.push(`/${tenant}/dialogue-editor/0/design`).then((r) => r);
    } else if (accessRights.hasSmartDocAccessOnly) {
      router.push(`/${tenant}/copilot`).then((r) => r);
    }
  }, [accessRights, router, tenant, redirect]);

  return accessRights;
};
